import { apiGetRH, apiPostRH, apiPutRH } from "#api/API_RH"
export const URL_BASE = "perfiles_puesto"
export const URL_PERFIL_PUESTO = (id) => `${URL_BASE}/${id}`
/**
 * Crea un perfil de puesto
 * @param {any} data
 * @param {apiPutRhSettingsParams} settings
 */
export const perfilPuestoPost = (data, settings = {}) => {
	apiPostRH(URL_BASE, data, settings)
}

/**
 *  Edita un perfil de puesto
 * @param {any} data
 * @param {apiPutRhSettingsParams} settings
 */
export const perfilPuestoPut = (id, data, settings = {}) => {
	apiPutRH(URL_PERFIL_PUESTO(id), data, settings)
}

/**
 *  Edita un perfil de puesto
 * @param {any} data
 * @param {apiGetRhParams} settings
 */
export const perfilPuestoGet = (id, settings = {}) => {
	apiGetRH(URL_PERFIL_PUESTO(id), settings)
}
