const { apiGetRH } = require("#api/API_RH")

const BASE = "colaboradores"
/**
 * Realiza una solicitud para obtener colaboradores.
 *
 * @param {apiGetRhParams} params - Parámetros para controlar la solicitud.
 * @returns {Promise<Object>} Una promesa que se resuelve con los datos de los colaboradores .
 */
export function colaboradoresGet(params = {}) {
	return apiGetRH(BASE, params)
}
