import {
	apiGetBaja,
	apiGetBajaTypes,
	bajaDebtsGet,
	bajaFilesGet,
	bajasContabilidadGet,
} from "#api/Api/baja"
import { api_rh_post, api_rh_put, apiGetRH } from "#api/API_RH"
import { useCallback, useEffect, useState } from "react"
import { UseDialogBajaContext } from "./DialogDetailBaja/components/DialogBajaContext"
import { set } from "react-hook-form"

/**
 *
 * @param {string} ruta - Ruta de la API a consultar.
 * @param {Object} settings - Parámetros para la consulta.
 * @param {Object} settings.params - Parámetros adicionales para la consulta.
 * @param {boolean} settings.reload - Indica si se debe actualizar el estado después de la consulta.
 * @param {boolean} settings.disable -Desactiva la consulta
 * @param {string} settings.nameData -nombre para los datos que se obtienen
 * @param {any} settings.dataType -tipo de datos que se obtienen
 * @returns {{ bajas: Array; loading: boolean; }}
 */

export function UseBajasV1(settings) {
	const getTypes = useCallback(
		(success, signal) => apiGetBaja({ ...settings, signal, success }),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	)
	const { data, loading } = UseBajasMaster(getTypes, settings)
	return { bajas: data || [], loading }
}

export default function UseBajas(ruta, settings = {}) {
	const {
		params,
		reload,
		disable = false,
		nameData = "bajas",
		dataType = [],
	} = settings
	const [bajas, setBajas] = useState([])
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		const controller = new AbortController()

		const success = (response) => {
			setBajas(response)
			setLoading(false)
		}

		if (!disable) {
			setLoading(true)
			apiGetRH(`baja/${ruta}`, {
				params,
				success,
				signal: controller.signal,
			})
		}

		return () => {
			controller.abort()
		}
	}, [ruta, params, reload, disable])
	return { [nameData]: bajas || dataType, loading }
}

export function bajasPost(data, settings = {}) {
	const { success = () => {} } = settings
	api_rh_post(`baja`, data, { success })
}
export function bajasPut(data, settings = {}) {
	api_rh_put(`baja`, data, settings)
}

//######################################################################################
// Función para manejar el uso de bajas
export function UseBajasMaster(apiGet, settings = {}) {
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(true)

	const { reload, disable = false } = settings
	const handleSuccess = (response) => {
		setData(response)
		setLoading(false)
	}

	// Función para manejar el efecto
	const handleEffect = () => {
		const controller = new AbortController()

		if (!disable) {
			setLoading(true)
			apiGet(handleSuccess, controller.signal)
		}

		return () => controller.abort()
	}

	useEffect(handleEffect, [apiGet, reload, disable])

	return { data, loading }
}

export function UseBajasTypes(settings = {}) {
	const getTypes = useCallback(
		(success, signal) => apiGetBajaTypes({ ...settings, signal, success }),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	)
	const { data, loading } = UseBajasMaster(getTypes, settings)
	return { types: data || [], loading }
}

export function UseBajasDebts(id, disable, reload) {
	const [debts, setDebts] = useState([])
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		const controller = new AbortController()
		const signal = controller.signal
		setLoading(true)
		const success = (response) => {
			setDebts(response)
			setLoading(false)
		}
		if (!disable) {
			bajaDebtsGet(id, { signal, success })
		}
		return () => {
			controller.abort()
		}
	}, [disable, id, reload])
	return { debts, loading }
}

export function UseBajasContabilidad(disable, actualizar) {
	const { baja } = UseDialogBajaContext()
	const [debts, setDebts] = useState([])
	const [loading, setLoading] = useState([])

	useEffect(() => {
		const controller = new AbortController()
		const signal = controller.signal
		setLoading(true)
		const success = (response) => {
			setDebts(response)
			setLoading(false)
		}
		if (!disable) {
			bajasContabilidadGet(baja.id, { signal, success })
		}
		return () => {
			controller.abort()
		}
	}, [baja.id, disable, actualizar])
	return { debts, loading }
}

export function UseBajasFiles(actualizar) {
	const { baja } = UseDialogBajaContext()
	const [files, setFiles] = useState([])
	const [loading, setLoading] = useState([])

	useEffect(() => {
		const controller = new AbortController()
		const signal = controller.signal
		setLoading(true)
		const success = (response) => {
			setFiles(response)
			setLoading(false)
		}
		bajaFilesGet(baja.id, { signal, success })
		return () => {
			controller.abort()
		}
	}, [baja.id, actualizar])
	return { files, loading }
}
