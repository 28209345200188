import { saveAs } from "file-saver"
const { apiGetRH, apiPutRH } = require("#api/API_RH")

// import { API } from "#api/API_RH"
const BAJA = "baja"
const BAJA_TYPES = `baja/types`
const BAJAS_DEPT = (id) => `baja/${id}/dept`
const BAJAS_CONTABILIDAD = (id) => `baja/${id}/contabilidad`
const CARTA_RENUNCIA = (id) => `baja/${id}/carta_renuncia`
const FILES = (id) => `baja/${id}/files`

export function apiGetBaja(params = {}) {
	apiGetRH(BAJA, params)
}

export function apiGetBajaTypes(settings = {}) {
	apiGetRH(BAJA_TYPES, settings)
}
/**
 *
// <----------- CONTABILIDAD ---------->
* @param {int} id identificador de la baja
* @param {apiGetRhParams} settings
*/
export function bajasContabilidadGet(id, settings = {}) {
	apiGetRH(BAJAS_CONTABILIDAD(id), settings)
}
/**
 *
 * @param {int} id - identificador de la baja
 * @param {any} data - datos a enviar
 * @param {apiPutRhSettingsParams} settings
 */
export function bajasContabilidadPut(id, data, settings = {}) {
	apiPutRH(BAJAS_CONTABILIDAD(id), data, settings)
}
// <------- END CONTABILIDAD ---------->

// <----------- DEPT ---------->
export function bajaDebtsGet(id, settings = {}) {
	apiGetRH(BAJAS_DEPT(id), settings)
}

export function bajasDeptPut(id, data, settings) {
	apiPutRH(BAJAS_DEPT(id), data, {
		...settings,
		message: { success: "Adeudos subidos con éxito" },
	})
}
//<----------- END DEPT ---------->

/**
 *
 * @param {int} id - identificador de la baja
 * @param {apiGetRhParams} settings
 */
export function bajaFilesGet(id, settings = {}) {
	apiGetRH(FILES(id), settings)
}
/**
 *
 * @param {int} id - identificador de la baja
 * @param {any} data - datos a enviar
 * @param {apiPutRhSettingsParams} settings
 */
export function bajaFilesPut(id, data, settings = {}) {
	apiPutRH(FILES(id), data, settings)
}

export function bajaCartaRenunciaGet(id) {
	const success = (data) => {
		saveAs(data.data, `carta_renuncia.pdf`)
	}
	apiGetRH(CARTA_RENUNCIA(id), {
		success,
		all: true,
		axiosProps: { responseType: "blob" },
	})
}
