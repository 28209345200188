import axios from "axios"
import { toast } from "react-toastify"
import "./types"

const URL_API = process.env.REACT_APP_SERVER_URL
const token = localStorage.getItem("token")

const gestor_rh = axios.create({
	baseURL: URL_API + "rh/",
	headers: {
		Authorization: "Token " + token,
	},
})

/**
 * Realiza una solicitud GET a recursos humanos.
 * @param {apiGetParams} options - Objeto de configuración.
 * @returns {Promise} - La respuesta de la API.
 */
export const api_rh_get = async (url, options = {}) => {
	//propiedades de callback
	const { success = () => {} } = options
	const { successAll = () => {} } = options
	const { fail = () => {} } = options
	const { failMessage = "Error al obtener datos " } = options

	const { setData = () => {} } = options
	const { loading = () => {} } = options
	//propiedades de axios
	const { axiosProps = {} } = options
	const { params = {} } = options
	const { signal = null } = options
	try {
		const response = await gestor_rh.get(url, { params, signal, ...axiosProps })
		success(response?.data?.data)
		successAll(response)
		setData(response?.data?.data)
		loading()
		return response
	} catch (error) {
		if (axios.isCancel(error)) {
			console.info("La solicitud fue cancelada")
		} else {
			console.error(`url: "/${url}"`, error)
			toast.error(failMessage)
			fail(error)
		}
		return null
	}
}
/**
 * Realiza una solicitud POST a recursos humanos.
 * @param {string} url
 * @param {object} datos
 * @param {Object} settings - Objeto de configuración.
 * @param {AxiosRequestConfig} settings.axios
 * @param {NotificationMessage} settings.message
 * @param {Function} settings.params query params para axios
 * @param {Function} settings.success Función que recibe como parámetro la data de la respuesta
 * @param {Function} settings.fail Función que recibe como parámetro el error
 *
 * @returns {Promise<object>} - La respuesta de la API.
 */
export const api_rh_post = async (url, datos, settings = {}) => {
	const { message = {} } = settings
	const { axios = {} } = settings
	const { params = {} } = settings
	const { success = () => {} } = settings
	const { fail = () => {} } = settings
	try {
		const request = gestor_rh.post(url, datos, { params, ...axios })
		notificationPromise(request, message)
		const response = await request
		success(response?.data?.data)
		return response
	} catch (error) {
		console.error(error)
		fail(error)
		return null
	}
}

/**
 * Realiza una solicitud PUT a recursos humanos.
 * @param {string} url
 * @param {object} datos
 * @param {Object} settings - Objeto de configuración.
 * @param {AxiosRequestConfig} settings.axios
 * @param {NotificationMessage} settings.message
 * @param {Function} settings.params query params para axios
 * @param {Function} settings.success Función que recibe como parámetro la data de la respuesta
 * @param {Function} settings.fail Función que recibe como parámetro el error
 *
 * @returns {Promise<object>} - La respuesta de la API.
 *
 */
export const api_rh_put = async (url, datos, settings = {}) => {
	const { message = {} } = settings
	const { axios = {} } = settings
	const { params = {} } = settings
	const { success = () => {} } = settings
	const { fail = () => {} } = settings

	try {
		const request = gestor_rh.put(url, datos, { params, ...axios })
		notificationPromise(request, message)
		const response = await request
		success(response?.data?.data)
		return response
	} catch (error) {
		console.error(error)
		fail(error)
		return null
	}
}

/**
 * Realiza una solicitud GET a recursos humanos.
 * @param {apiGetRhParams} settings
 * @returns {Promise} - La respuesta de la API.
 */
export const apiGetRH = async (urlExtend, settings = {}) => {
	const {
		signal,
		params,
		axiosProps = {},
		failMessage = "Error al obtener datos",
	} = settings
	const callback = (fn, arg) => typeof fn === "function" && fn(arg)
	const get = () =>
		gestor_rh.get(urlExtend, {
			params,
			signal,
			...axiosProps,
		})
	const fail = (error) => {
		callback(settings.fail, error)
		toast.error(failMessage)
	}
	return apiControl(get, { ...settings, fail }, false)
}

/**
 * @param {string} url - Url complementaria de la api.
 * @param {Object} datos - La data a enviar.
 * @param {apiPutRhSettingsParams} settings - Configuración adicional.
 * @returns {Promise<object>} - La respuesta de la API.
 */
export function apiPostRH(url, datos, settings = {}) {
	const { params, axiosProps = {} } = settings
	const post = () => gestor_rh.post(url, datos, { params, ...axiosProps })
	return apiControl(post, settings)
}

/**
 * @param {string} url - Url complementaria de la api.
 * @param {Object} datos - La data a enviar.
 * @param {apiPutRhSettingsParams} settings - Configuración adicional.
 * @returns {Promise<object>} - La respuesta de la API.
 */
export function apiPutRH(url, datos, settings = {}) {
	const { params, axiosProps = {} } = settings
	const put = () => gestor_rh.put(url, datos, { params, ...axiosProps })
	return apiControl(put, settings)
}

/**
 * @param {string} url - Url complementaria de la api.
 * @param {Object} datos - La data a enviar.
 * @param {apiPutRhSettingsParams} settings - Configuración adicional.
 * @returns {Promise<object>} - La respuesta de la API.
 */
export function apiDeleteRH(url, settings = {}) {
	const { params, axiosProps = {} } = settings
	const apiDelete = () => gestor_rh.delete(url, { params, ...axiosProps })
	return apiControl(apiDelete, settings)
}

/**
 * @async
 * @param {function} callApi Función que se ejecuta para realizar la petición
 * @param {apiControlParams } settings
 * @param {boolean } notification activa las notificaciones
 * @returns
 */
async function apiControl(callApi, settings = {}, notification = true) {
	const { success, fail, end, all, message } = settings
	const callback = (fn, arg) => typeof fn === "function" && fn(arg)

	try {
		const request = callback(callApi)
		if (notification) notificationPromise(request, message)
		const response = await request
		callback(success, all ? response : response?.data?.data)
		return response
	} catch (error) {
		if (axios.isCancel(error)) console.info("La solicitud fue cancelada")
		else {
			console.error(error)
			callback(fail, error)
		}
		return null
	} finally {
		callback(end)
	}
}

/**
 * Notificaciones para el usuario cuando envía edita o elimina
 * @param {Promise} request
 * @param {NotificationMessage} message
 */
export function notificationPromise(request, message = {}) {
	const { pending = "Enviando..." } = message
	const { success = false } = message
	const { error = false } = message

	toast.promise(request, {
		pending,
		success: {
			render({ data }) {
				return success || `${data.data.message}`
			},
		},
		error: {
			render({ data }) {
				return (
					error ||
					(data?.response?.data?.message
						? data.response.data.message
						: data.message)
				)
			},
		},
	})
}
