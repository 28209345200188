import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
// common
import { ButtonFile } from "#common/Buttons"
import { HasPermission, formatMoney } from "#common/Funciones"
// Constants
import { PERFILES } from "#constants/Strings"
import { COLOR_PRIMARY, COLOR_THIRD, RED_COLOR } from "#constants/Styles"
//api
import { getDatosGeneralesColaborador } from "#api/ApiColaboradores"
import { api_rh_put } from "#api/API_RH"
// Context
import {
	DetalleColaboradorProvider,
	SolicitudesProvider,
} from "./Solicitudes/SolicitudesContext"
//material
import { Box, Grid, Stack, Typography } from "@mui/material/"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"

//pestañas
import VacationDetail from "Components/vacation/VacationDetail"
import Asset from "../Inventario/Asset"
import Documentos from "./Detail/Documentos"
import InventarioRH from "./InventarioRH"
import MiEquipoCards from "./MiEquipo/MiEquipoCards"
import Solicitudes from "./Solicitudes/Solicitudes"
import MiUniforme from "./Uniforme/MiUniforme"
import { LoadViewDetail } from "../Common/LoadingView"
import { startActualizacion } from "../../app/actualizar"

//Icons
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings"
import AirplaneTicketIcon from "@mui/icons-material/AirplaneTicket"
import ArticleIcon from "@mui/icons-material/Article"
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd"
import AttachMoneyIcon from "@mui/icons-material/AttachMoney"
import CameraAltIcon from "@mui/icons-material/CameraAlt"
import CheckroomIcon from "@mui/icons-material/Checkroom"
import GroupsIcon from "@mui/icons-material/Groups"
import VerifiedIcon from "@mui/icons-material/Verified"

//Tap
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import Tab from "@mui/material/Tab"
import ButtonsColaborador from "./DetalleColaborarComponents/DetalleColaborarComponents"
import { ColaboradorImage } from "./DetalleColaborarComponents/ColaboradorImage"
import { UseColaborador } from "./UseColaborador"

// import MiEquipoTest from "./MiEquipo/MiEquipoTest copy"

const DetalleColaborador = ({ idColaborador }) => {
	const [value, setValue] = useState(0)
	const [dataColaborador, setDataColaborador] = useState({})
	const reloadDataNow = () => setReloadData(!reloadData)
	const [showLoading, setShowLoading] = useState(true)
	const token = useSelector((state) => state.user.token)
	//permisos
	const is_rh = HasPermission(PERFILES.RH)
	//Actualizar información
	const { actualizar } = useSelector((state) => state.actualizar)
	const [reloadData, setReloadData] = useState(false)
	useEffect(() => {
		setShowLoading(true)
		setDataColaborador({})
		if (idColaborador == null) return
		getDatosGeneralesColaborador(token, idColaborador)
			.then((response) => {
				setDataColaborador(response?.data)
				setShowLoading(false)
			})
			.catch((err) => {
				alert("Error al obtener la información del colaborador " + err)
			})
	}, [idColaborador, reloadData, token, actualizar])

	let ShowVacation = HasPermission([
		PERFILES.GUATEMALA,
		PERFILES.GRTE_GUAT,
		PERFILES.RH,
	])

	let ShowTeam = HasPermission([
		PERFILES.GUATEMALA,
		PERFILES.GER_GUAT,
		PERFILES.GRTE,
		PERFILES.RH,
	])

	//permisos para quitar visibilidad a Guatemala
	let notView = true
	const is_guatemala =
		HasPermission(PERFILES.GUATEMALA, true) ||
		dataColaborador?.sucursal === "Guatemala"

	if (
		HasPermission(PERFILES.GUATEMALA, true) ||
		dataColaborador?.sucursal === "Guatemala"
	) {
		notView = false
	}

	let valEquipo = false
	let taps = []
	//se inicia cuando tengamos los datos del colaborador
	if (!showLoading) {
		let palabras_clave = [
			"coordinador",
			"jefe",
			"jefatura",
			"gerente",
			"director",
			"gerencia",
		]

		valEquipo =
			haveString(palabras_clave, dataColaborador?.perfil?.toLowerCase()) ||
			ShowTeam

		taps = [
			{
				show: true,
				label: "Inventario RH",
				icon: <AssignmentIndIcon />,
				component: <InventarioRH dataColaborador={dataColaborador} />,
			},
			{
				show: true,
				label: "Mis Documentos",
				icon: <ArticleIcon />,
				component: <Documentos colaboradorId={dataColaborador.id} />,
			},
			{
				show: notView,
				label: "caja de ahorro y prestamos",
				icon: <AttachMoneyIcon />,
				component: <Solicitudes />,
			},
			{
				show: true,
				id: "tabUniforme",
				label: "uniforme",
				icon: <CheckroomIcon />,
				component: (
					<MiUniforme
						dataColaborador={dataColaborador}
						setReloadData={setReloadData}
						reloadData={reloadData}
					/>
				),
			},
			{
				show: true,
				id: "responsivas",
				label: "Mis herramientas",
				icon: <AdminPanelSettingsIcon />,
				component: <Asset dataColaborador={dataColaborador} />,
			},
			{
				show: valEquipo,
				label: "Mi equipo",
				icon: <GroupsIcon />,
				component: (
					<MiEquipoCards
						colaborador={dataColaborador}
						idColaborador={dataColaborador.id}
						reporta_a={dataColaborador.perfil_puesto}
					/>
				),
			},
			{
				show: ShowVacation,
				label: "Vacaciones",
				icon: <AirplaneTicketIcon />,
				component: <VacationDetail colaborador={dataColaborador.id} />,
			},
		]
	}

	const handleChange = (event, newValue) => {
		setValue(newValue)
	}

	if (idColaborador == null) return <></>
	if (showLoading) return <LoadViewDetail />
	else
		return (
			<Box>
				<DetalleColaboradorProvider
					colaborador={dataColaborador}
					reload={reloadDataNow}
					isRH={is_rh}
				>
					{/* Imaginen colaborador  */}
					<Grid
						container
						width={"100%"}
					>
						<Grid
							item
							xs={12}
						>
							<div style={{ width: "100%" }}>
								<ColaboradorHeader
									colaborador={dataColaborador}
									is_rh={is_rh}
									is_guatemala={is_guatemala}
									reload={reloadDataNow}
								/>
							</div>
						</Grid>

						<Grid
							container
							item
							xs={12}
							sx={{ color: COLOR_PRIMARY }}
						>
							{dataColaborador?.fecha_baja === null ? (
								<Grid
									item
									md={12}
									xs={12}
								>
									<TabArray
										colaboradorId={dataColaborador.id}
										sucursal={dataColaborador.sucursal}
										handleChange={handleChange}
										value={value}
										tabs={taps}
									/>
								</Grid>
							) : (
								<></>
							)}
						</Grid>
					</Grid>
				</DetalleColaboradorProvider>
			</Box>
		)
}

export default DetalleColaborador

const TabArray = ({ colaboradorId, sucursal, tabs = [] }) => {
	let tabsNew = []
	let panelNew = []
	const [value, setValue] = useState(0)

	const handleChange = (event, newValue) => {
		setValue(newValue)
	}

	tabs.forEach((tab) => {
		if (tab.show !== false) {
			tabsNew.push(
				<Tab
					// id={tab?.id}
					key={tab.label}
					icon={tab.icon}
					label={tab.label}
					iconPosition="start"
					// value={tabsNew.length}
					// {...a11yProps(tabsNew.length)}
				/>,
			)
			panelNew.push(
				<TabPanel
					key={tab.label}
					value={panelNew.length}
				>
					{tab.component}
				</TabPanel>,
			)
		}
	})
	return (
		<Box sx={{ width: "100%", typography: "body1" }}>
			<TabContext value={value}>
				<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
					<TabList
						onChange={handleChange}
						aria-label="lab API tabs example"
						variant="scrollable"
						allowScrollButtonsMobile
						scrollButtons
					>
						{tabsNew}
					</TabList>
				</Box>
				<SolicitudesProvider
					colaboradorID={colaboradorId}
					sucursal={sucursal}
				>
					{panelNew}
				</SolicitudesProvider>
			</TabContext>
		</Box>
	)
}

function haveString(palabras, perfil = "") {
	let has_permission = false
	perfil = perfil?.toLowerCase()

	palabras.forEach((palabra) => {
		if (perfil?.includes(palabra?.toLowerCase())) has_permission = true
	})

	return has_permission
}

const ColaboradorHeader = ({ colaborador, is_rh, is_guatemala, reload }) => {
	const { fecha_baja } = colaborador
	const buttonsHidden = fecha_baja != null || !is_rh
	return (
		<Box>
			<ButtonsColaborador
				dataColaborador={colaborador}
				reloadNow={reload}
				hidden={buttonsHidden}
			/>
			<Grid2
				container
				spacing={3}
				style={{ width: "100%" }}
				justifyContent={"left"}
				columns={{ md: 12, lg: 16 }}
			>
				<Grid2
					xs={12}
					md={2}
				>
					<ColaboradorImage
						colaborador={colaborador}
						is_rh={is_rh}
					/>
				</Grid2>
				<Grid2
					xs={12}
					md={10}
					lg={14}
				>
					<ColaboradorInfo
						colaborador={colaborador}
						is_guatemala={is_guatemala}
					/>
				</Grid2>
			</Grid2>
		</Box>
	)
}

const ColaboradorInfo = ({ colaborador, is_guatemala }) => {
	const { nombre_completo, perfil_puesto, jefe_directo, email } = colaborador
	const { telefono_lubtrac, sucursal, no_empleado, centro_costos } = colaborador
	const { fondo_fijo } = colaborador
	const { fecha_baja, motivo_baja } = colaborador

	let verificado = colaborador?.verificado ? (
		<VerifiedIcon sx={{ marginLeft: "5px", color: "#49A7A7" }} />
	) : null
	const FieldInfo = ({ campo = "", valor, sx }) => {
		const styleData = { fontSize: 21, fontWeight: "bold", ...sx }
		if (valor == null) return null
		return (
			<Typography
				sx={styleData}
				mb={-0.5}
				color={"primary"}
				// noWrap={{ md: false, xs: false }}
			>
				{`${campo} ${valor}`}
			</Typography>
		)
	}
	const alignItems = { md: "start", xs: "center" }
	return (
		<Stack
			spacing={{ xs: 0, md: 3, lg: 20 }}
			useFlexGap
			direction={{ md: "row", xs: "column" }}
			justifyContent={"top"}
			alignItems={alignItems}
		>
			<Stack alignItems={alignItems}>
				<Typography
					sx={{ fontSize: 24, fontWeight: "bold" }}
					mb={-0.5}
					color={"primary"}
				>
					{nombre_completo}
					{verificado}
				</Typography>

				<FieldInfo valor={perfil_puesto} />
				<FieldInfo valor={telefono_lubtrac} />
				<FieldInfo valor={email} />
				<FieldInfo
					campo={"Jefe :"}
					valor={jefe_directo}
				/>
				<FieldInfo
					campo={"Fecha Baja :"}
					valor={fecha_baja}
					sx={{ color: RED_COLOR }}
				/>
				<FieldInfo
					campo="Motivo baja :"
					valor={motivo_baja}
					sx={{ color: RED_COLOR }}
				/>
			</Stack>
			<Stack alignItems={alignItems}>
				<FieldInfo
					campo="No. Empleado :"
					valor={no_empleado}
				/>
				<FieldInfo
					campo={"Sucursal :"}
					valor={sucursal}
				/>
				<FieldInfo
					campo="Departamento :"
					valor={centro_costos}
				/>
				<FieldInfo
					campo={"Fondo Fijo :"}
					valor={formatMoney(fondo_fijo, is_guatemala ? "Q" : "$")}
				/>
			</Stack>
		</Stack>
	)
}
