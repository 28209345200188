import React from "react"
import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"
import { useMemo } from "react"
export const InputAutocomplete = ({
	label,
	options,
	value,
	setValue,
	valueId,
	valueLabel = "label",
}) => {
	const selectedValues = useMemo(() => {
		if (!value) return ""
		if (valueId) return options.filter((v) => v[valueId] === value)?.pop()
		else return value
	}, [options, value, valueId])

	const onChangeValues = useMemo(() => {
		return (event, newValue) => {
			if (newValue == null) setValue("")
			else if (valueId) setValue(newValue[valueId])
			else setValue(newValue)
		}
	}, [setValue, valueId])

	return (
		<Autocomplete
			getOptionLabel={(option) => {
				if (!option) return ""
				return `${option[valueLabel]}`
			}}
			fullWidth
			disablePortal
			id="InputAutocomplete"
			options={options}
			value={selectedValues}
			onChange={onChangeValues}
			renderInput={(params) => (
				<TextField
					{...params}
					label={label}
				/>
			)}
		/>
	)
}
