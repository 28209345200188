import { MasterDialog } from "#common/Dialog/Dialog"
import { HasPermission } from "#common/Funciones"
import { Stack } from "@mui/material"
import { useCallback, useState } from "react"
import * as TEXT from "../TextBaja"
// Componentes
import ApprovalRH from "./components/ApprovalRH"
import DebtBajas from "./components/DebtBajas"
import InfoBajasDialog, {
	InformationSolicitudBajas,
	InformationTracsaSolicitudBajas,
} from "./components/InfoBajasDialog"
// -------------

import { DialogBajaProvider } from "./components/DialogBajaContext"
import Tabs from "#common/tabs/Tabs"
import { TERMINATION } from "#constants/StatusRequest "
import ContabilidadBajas from "./components/ContabilidadBajas"
import Documents from "./components/Documents"
import { PERFILES } from "#constants/Strings"
import { useSelector } from "react-redux"

export default function DialogDetailBaja({
	baja,
	open,
	setOpen,
	reload,
	dialogIndex,
	setDialogIndex,
}) {
	const isRH = HasPermission("RH")
	const isGRTE = HasPermission(PERFILES.GRTE)
	const [buttonsDialog, setButtonsDialog] = useState(null)
	const closeDialog = useCallback(() => {
		setButtonsDialog(null)
		setOpen(false)
		setDialogIndex(0)
	}, [setDialogIndex, setOpen])

	const tabsChange = useCallback((value) => {
		setButtonsDialog(null)
	}, [])
	const tabsShow =
		(baja?.termination_status_id !== TERMINATION.FINALIZADA ||
			baja?.termination_status_id !== TERMINATION.RECHAZADA) &&
		baja?.termination_status_id !== TERMINATION.SOLICITADA

	const request_collaborator_id = useSelector(
		(state) => state.user.colaborador.id,
	)
	const tabs = [
		{
			title: "Solicitud",
			component: <InformationSolicitudBajas baja={baja} />,
			show: (isRH || baja.request_collaborator === request_collaborator_id)
				
		},
		{
			title: "Tracsa",
			component: <InformationTracsaSolicitudBajas baja={baja} />,
			show: isRH && tabsShow,
		},
		{
			title: "Adeudos",
			component: <DebtBajas />,
			show: tabsShow,
		},
		{
			title: "Contabilidad",
			component: <ContabilidadBajas />,
			show: isRH && tabsShow,
		},
		{
			title: "Documentos",
			component: <Documents />,
			show:
				(isRH || baja.request_collaborator === request_collaborator_id) &&
				tabsShow,
		},
	]

	return (
		<MasterDialog
			setOpen={setOpen}
			open={open}
			title={TEXT.TITLE_DIALOG}
			buttons={buttonsDialog}
			maxWidth={"md"}
			closeDialog={closeDialog}
		>
			<DialogBajaProvider
				baja={baja}
				reload={reload}
				isRH={isRH}
				setButtonsDialog={setButtonsDialog}
				closeDialog={closeDialog}
			>
				<Stack
					container
					spacing={2}
					rowSpacing={3}
				>
					<InfoBajasDialog
						baja={baja}
						notAll={!isRH}
					/>
					<Tabs
						tabs={tabs}
						onChange={tabsChange}
						value={dialogIndex}
						setValue={setDialogIndex}
					/>
					<ApprovalRH
						baja={baja}
						hidden={!isRH}
					/>
				</Stack>
			</DialogBajaProvider>
		</MasterDialog>
	)
}
