import axios from 'axios'
import { apiGetRH, apiPostRH, apiPutRH } from './API_RH'
const URLAPI = process.env.REACT_APP_SERVER_URL

export const loginWeb = async(login_type, email, username, password) => {
    const request = await axios.post(URLAPI+'core/login_ultimate',{email, login_type, username, password, platform: 'RH'})
    return request.data
}

export const getSucursales = async(token, all = false) => {
    const request = await axios.get(URLAPI+'core/sucursales',{ headers: { Authorization: 'Token ' + token }} )
    return request.data
}
export const getSucursalesAll = async(token) => {
    const request = await axios.get(URLAPI+'core/sucursales',{ headers: { Authorization: 'Token ' + token },params:{all:true}} )
    return request.data
}

export const getDepartamentos = async(token) => {
    const request = await axios.get(URLAPI+'rh/areas',{ headers: { Authorization: 'Token ' + token }} )
    return request.data
}


/******** PERFILES DE PUESTO *******/
export const getPerfiles = async(token) => {
    const request = await axios.get(URLAPI + 'rh/perfiles_puesto',{ headers: { Authorization: 'Token ' + token }})
    return request.data
}
export const getPerfilesAll = async(token) => {
    const request = await axios.get(URLAPI + 'rh/perfiles_puesto',{ headers: { Authorization: 'Token ' + token },params:{all:true}})
    return request.data
}

export const getPerfilDetail = async(token,perfil_id) => {
    const request = await axios.get(URLAPI +'rh/perfiles_puesto/'+perfil_id,{ headers: { Authorization: 'Token ' + token }})
    return request.data
}

export const getPerfilCompetencias = async(token,perfil_id) => {
    const request = await axios.get(URLAPI+'rh/perfil_competencias',{ params:{'perfil_id':perfil_id},headers: { Authorization: 'Token ' + token }})
    return request.data
}
/**
 * @deprecated
 */
export const saveProfile = async(token, id, nombre_perfil, area_id, reporta_a, objetivo, list_educacion, list_capacitacion, list_experiencia, list_idiomas, list_software, list_adicionales, list_responsabilidades, interacciones_internas, interacciones_externas,listTools ) => {
    const request = await axios.post(URLAPI+'rh/perfiles_puesto',{nombre_perfil, id, area_id, reporta_a, objetivo, list_educacion, list_capacitacion, list_experiencia, list_idiomas, list_software, list_adicionales, list_responsabilidades, interacciones_internas, interacciones_externas,listTools},{ headers: { Authorization: 'Token ' + token }})
    return request.data
}

export const updateProfile = async(token, perfil_id, nombre, area, reporta_a, objetivo, list_educacion, list_capacitacion, list_experiencia, list_idiomas, list_software, list_adicionales, list_responsabilidades, interacciones_internas, interacciones_externas ,listTools) => {
    const request = await axios.put(URLAPI+'rh/perfiles_puesto/'+perfil_id,{nombre, area, reporta_a, objetivo, list_educacion, list_capacitacion, list_experiencia, list_idiomas, list_software, list_adicionales, list_responsabilidades, interacciones_internas, interacciones_externas,listTools},{ headers: { Authorization: 'Token ' + token }})
    return request.data
}

export const autorizarPerfil = async (token, perfil_id) => {
    const request = await axios.post(URLAPI + 'rh/autorizar_perfil', {perfil_id}, { headers: { Authorization: 'Token ' + token } })
    return request.data
}

export const rechazarPerfil = async (token, perfil_id) => {
    const request = await axios.delete(URLAPI + `rh/perfiles_puesto/${perfil_id}`,  {  headers: { Authorization: 'Token ' + token }, params: { perfil_id } })
    return request.data
}

export const deshabilitarPerfil = async (token, perfil_id) => {
    const request = await axios.delete(URLAPI + `rh/perfiles_puesto/${perfil_id}`,  {  headers: { Authorization: 'Token ' + token }, params: { perfil_id } })
    return request.data
}






/******** COLABORADORES *******/

export const saveColaboMasiva = async(token, dataForm) => {
    const request = await axios.post(URLAPI+'rh/colaboradores', dataForm, { headers: { Authorization: 'Token ' + token}})
    return request.data
}











export const saveAutoColaborador = async(token,jsonAutoColabo) => {
    const request = await axios.post(URLAPI+'rh/auto_colaborador',jsonAutoColabo,{ headers: { Authorization: 'Token ' + token }})
    return request.data
}

export const deleteAutoColaborador = async(token,auto_id) => {
    const request = await axios.delete(URLAPI+'rh/auto_colaborador',{ headers: { Authorization: 'Token ' + token },data:{auto_id}})
    return request.data
}

export const saveFamiliarColaborador = async(token,jsonAutoColabo) => {
    const request = await axios.post(URLAPI+'rh/familiar_colaborador',jsonAutoColabo,{ headers: { Authorization: 'Token ' + token }})
    return request.data
}

export const deleteFamiliarColaborador = async(token,familiar_id) => {
    const request = await axios.delete(URLAPI+'rh/familiar_colaborador',{ headers: { Authorization: 'Token ' + token },data:{familiar_id}})
    return request.data
}

export const saveContactoColaborador = async(token,jsonContactoColabo) => {
    const request = await axios.post(URLAPI+'rh/contacto_colaborador',jsonContactoColabo,{ headers: { Authorization: 'Token ' + token }})
    return request.data
}

export const saveBeneficiarioColaborador = async(token,jsonContactoColabo) => {
    const request = await axios.post(URLAPI+'rh/beneficiario_colaborador',jsonContactoColabo,{ headers: { Authorization: 'Token ' + token }})
    return request.data
}

export const saveEscolarColaborador = async(token,jsonEscolarColabo) => {
    const request = await axios.post(URLAPI+'rh/escolar_colaborador',jsonEscolarColabo,{ headers: { Authorization: 'Token ' + token }})
    return request.data
}

export const deleteEscolarColaborador = async(token,escolar_id) => {
    const request = await axios.delete(URLAPI+'rh/escolar_colaborador',escolar_id,{ headers: { Authorization: 'Token ' + token }})
    return request.data
}

// export const saveCursoColaborador = async(token, nombre, descripcion, institucion, duracion, certificado, colaborador) => {
//     var formData = new FormData();
//     formData.append("file_certificado", certificado);
//     formData.append("nombre", nombre);
//     formData.append("descripcion", descripcion);
//     formData.append("institucion", institucion);
//     formData.append("duracion", duracion);
//     formData.append("colaborador", colaborador);
//     const request = await axios.post(URLAPI+'rh/curso_colaborador',formData,{ headers: { Authorization: 'Token ' + token, 'Content-Type': 'multipart/form-data' }})
//     return request.data
// }

export const saveIdiomaColaborador = async(token,jsonIdiomaColabo) => {
    const request = await axios.post(URLAPI+'rh/idioma_colaborador',jsonIdiomaColabo,{ headers: { Authorization: 'Token ' + token }})
    return request.data
}

export const saveTrabajoColaborador = async(token,jsonTrabajoColabo) => {
    const request = await axios.post(URLAPI+'rh/trabajo_colaborador',jsonTrabajoColabo,{ headers: { Authorization: 'Token ' + token }})
    return request.data
}

export const saveTrayectoriaColaborador = async(token,jsonTrayectoriaColabo) => {
    const request = await axios.post(URLAPI+'rh/trayectoria_colaborador',jsonTrayectoriaColabo,{ headers: { Authorization: 'Token ' + token }})
    return request.data
}

export const saveInstitucionalesColaborador = async(token,photo,colaborador_id,no_empleado,sucursal_id,perfil_id,jefe) => {
    var formData = new FormData();
    formData.append("photo", photo);
    formData.append("colaborador_id", colaborador_id);
    formData.append("no_empleado", no_empleado);
    formData.append("sucursal_id", sucursal_id);
    formData.append("perfil_id", perfil_id);
    formData.append("jefe", jefe);
    const request = await axios.post(URLAPI+'rh/institucionales_colaborador',formData,{ headers: { Authorization: 'Token ' + token, 'Content-Type': 'multipart/form-data' }})
    return request.data
}

export const getColaboradorDetail = async(token,colaborador_id) => {
    const request = await axios.get(URLAPI+'rh/colaborador',{ params:{colaborador_id},headers: { Authorization: 'Token ' + token }})
    return request.data
}

export const noHaceCursos = async(token,colaborador_id) => {
    const request = await axios.post(URLAPI + 'rh/no_hace_cursos', { colaborador_id }, {headers: { Authorization: 'Token ' + token }})
    return request.data
}

/******** Vacantes *******/
export const getVacantes = async(token, activas) => {
    const request = await axios.get(URLAPI+'rh/vacantes?activas='+activas,{ headers: { Authorization: 'Token ' + token }})
    return request.data
}

/**
 * 
 * @param {int} vacante_id 
 * @param {apiGetRhParams} settings
 */
export const vacanteGet = (vacante_id,settings) => {
    apiGetRH (`vacantes/${vacante_id}`,settings)
}
export const getVacanteDetail = async(token,vacante_id) => {
    const request = await axios.get(URLAPI+'rh/vacante',{ params:{vacante_id},headers: { Authorization: 'Token ' + token }})
    return request.data
}

export const saveVacante = async(token, perfil_vacante, jefe, tipo_vacante, sueldo_mensual, bono_trimestral, garantia, duracion, sucursal, colaborador,list_responsabilidades, list_educacion, list_experiencia, list_adicionales,list_tools ) => {
    const request = await axios.post(URLAPI+'rh/vacantes',{perfil_vacante, jefe, tipo_vacante, sueldo_mensual, bono_trimestral, garantia, duracion, sucursal, colaborador, list_responsabilidades, list_educacion, list_experiencia, list_adicionales,list_tools},{ headers: { Authorization: 'Token ' + token }})
    return request.data
}
/**
 * 
 * @param {int} vacante_id 
 * @param {int} authorization_id 
 * @param {boolean} authorized 
 * @param {apiPutRhSettingsParams} settings 
 */
export const authorizationVacante = ( vacante_id,authorization_id,authorized,settings) => {
    apiPutRH(`vacantes/${vacante_id}/authorization/${authorization_id}`,{ authorized },settings)
    
}
export const autorizacionVacante = async(token, vacante, autorizacion,id_colaborador) => {
    const request = await axios.post(URLAPI+'rh/autorizacion_vacante',{ vacante, autorizacion, id_colaborador },{ headers: { Authorization: 'Token ' + token }})
    return request.data
}

/**
 * 
 * @param {int} vacante_id 
 * @param {any} data 
 * @param {apiPutRhSettingsParams} settings
 */
export const vacantePut=(vacante_id,data,settings)=>{
    apiPutRH(`vacantes/${vacante_id}`,data,settings)
}

export const recepcionRH = async (token, vacante) => {
    const request = await axios.post(URLAPI + 'rh/recepcion_vacante_rh', { vacante }, { headers: { Authorization: 'Token ' + token } })
    return request.data
}

export const rechazoRH = async (token, vacante,comentario) => {
    const request = await axios.post(URLAPI + 'rh/rechazo_vacante_rh', { vacante ,comentario}, { headers: { Authorization: 'Token ' + token } })
    return request.data
}
/**
 * 
 * @param {any} data 
 * @param {apiPutRhSettingsParams} settings
 */
export const candidatosPost = (data,settings) => {
    apiPostRH('candidatos', data,settings)
}
export const saveCandidatoVacante = async(token, cv, nombre, correo, telefono, vacanteID) => {
    var formData = new FormData();
    formData.append("cv", cv);
    formData.append("nombre", nombre);
    formData.append("correo", correo);
    formData.append("telefono", telefono);
    formData.append("vacante_id", vacanteID);
    const request = await axios.post(URLAPI+'rh/candicatos',formData,{ headers: { Authorization: 'Token ' + token, 'Content-Type': 'multipart/form-data' }})
    return request.data
}



export const getHistorialCandidato = async (token, candidato_id) => {
    const request = await axios.get(URLAPI + 'rh/historial_candidato', { params: { candidato_id }, headers: { Authorization: 'Token ' + token } })
    return request.data
}



export const getMisColaboradores = async(token,colaborador_id = null, ) => {
    var url = URLAPI+'rh/mis_colaboradores'
    if (colaborador_id != null) 
        url = url + '?colaborador_id=' + colaborador_id
    const request = await axios.get(url,{ headers: { Authorization: 'Token ' + token }})
    return request.data
}

/******** DOCUMENTACION *******/
export const getDocumentacion = async(token) => {
    const request = await axios.get(URLAPI+'rh/documentacion',{ headers: { Authorization: 'Token ' + token }})
    return request.data
}

/******** ORGANIGRAMA *******/
export const getOrganigrama = async(token) => {
    const request = await axios.get(URLAPI+'rh/organigrama',{ headers: { Authorization: 'Token ' + token }})
    return request.data
}

/******** CURSOS *******/
/**
 * @description optiene de la base de datos las plataformas de cursos 
 * @param {*} token  
 * @returns 
 */
export const getPlataforma = async(token) => {
    const request = await axios.get(URLAPI+'rh/cursos/plataforma',{ headers: { Authorization: 'Token ' + token }})
    return request.data
}
/**
 * @description Permite crear un cursos nuevo 
 * @param {*} token 
 * @param {*} datos 
 * @example postCursos(token,
 * {"curso": "",
 *	"link": "https://www",
 *	"objetivo": "",
 *	"contenido": "",
 *	"hora": 0,
 *	"colaborador_creador": 0,
 *	"plataforma": 3
	})
 * @returns 
 */
export const postCursos = async(token,datos ) => {
    const request = await axios.post(URLAPI+'rh/cursos',datos,{ headers: { Authorization: 'Token ' + token }})
    return request.data
}

export const getCursos = async(token,id=0) => {
    const request = await axios.get(URLAPI+`rh/cursos?id=${id}`,{ headers: { Authorization: 'Token ' + token }})
    return request.data
}
//Curso Colaborador 
export const getCursosPorColaborador = async(token,id=0) => {
    const request = await axios.get(URLAPI+`rh/cursos/cursoColaborador?id=${id}`,{ headers: { Authorization: 'Token ' + token }})
    return request.data
}
/**
 * permite filtrar datos con id o el status
 * @param {*} token 
 * @param {*} parametros filtros en la busqueda 
 * @returns 
 */
export const getCursosPorColaboradorb = async(token,parametros) => {
    const request = await axios.get(URLAPI+`rh/cursos/cursoColaborador?${parametros}`,{ headers: { Authorization: 'Token ' + token }})
    return request.data
}
/**
 * Modifica los datos del curso por colaborador 
 * 
 * @param {*} token 
 * @param {*} id El id de curso colaborador a modificar 
 * @param {*} datos Se envia los datos 	
		"status": numero,
		"colaborador_validacion": quien modifico el estado
	
 * @returns 
 */
export const putCursosPorColaborador = async(token,id=0,datos) => {
    const request = await axios.put(URLAPI+`rh/cursos/cursoColaborador?id=${id}`,datos,{ headers: { Authorization: 'Token ' + token }})

    return request.data
}
/**
 * Permite subir un PDF y generar la ruta 
 * @param {*} token 
 * @param {*} pdf Archivo PDF
 * @param {*} idCursoColab El Id del Curso Colaborador 
 * @returns 
 */
export const putCursosPorColaboradorPDF = async(token, pdf, idCursoColab,nombreCurso) => {
    var formData = new FormData();
    formData.append("pdf", pdf);
    formData.append("curso",nombreCurso)
    const request = await axios.put(URLAPI+`rh/cursos/cursoColaborador?PDF=1&id=${idCursoColab}`,formData,{ headers: { Authorization: 'Token ' + token, 'Content-Type': 'multipart/form-data' }})
    return request.data
}
//Curso Colaborador
export const getStatus = async(token) => {
    const request = await axios.get(URLAPI+`rh/cursos/status`,{ headers: { Authorization: 'Token ' + token }})
    return request.data
}
//Curso_Departamento
/**
 * postCursoDepartamento
 * @description permite mandar un objeto con un arreglo de cursos a dar de alta por departamento 
 * @param {*} token 
 * @param {*} datos @description {"cursos" : [{ "curso" : int,"mes" : int }]} 
 * @returns 
 */
export const postCursoDepartamento = async(token,datos) => {
    const request = await axios.post(URLAPI+'rh/cursos/curso_departamento',datos,{ headers: { Authorization: 'Token ' + token }})
    return request.data
}
export const getLinkPDF = async(token,link) => {
    const request = await axios.get(URLAPI+`rh/cursos/curso_departamento/link?url=${link}`,{ headers: { Authorization: 'Token ' + token }})
    return request.data
}
/**
 * 
 * @param {*} token 
 * @param {*} param 
 * @returns 
 */
export const getCursoDepartamento = async(token,parametro) => {
    const request = await axios.get(URLAPI+`rh/cursos/curso_departamento?${parametro}`,{ headers: { Authorization: 'Token ' + token }})
    return request.data
}

export const excelPrueba = async(token,excel,grupo) => {
    var formData = new FormData();
    formData.append("archivo", excel);
    formData.append("type", 1);
    formData.append("sucursal", 1);
    const request = await axios.post(URLAPI+'spas/leer_excel/',formData,{ headers: { Authorization: 'Token ' + token, 'Content-Type': 'multipart/form-data' }})
    return request.data
}
/**
 * @description permite subir un archivo exel
 * @param {*} token 
 * @param {*} exel 
 * @returns 
 */
export const putCursosExel = async(token, exel, ) => {
    var formData = new FormData();
    formData.append("exel", exel);
    const request = await axios.post(URLAPI+`rh/cursos/curso_departamento/ExelCursos`,formData,{ headers: { Authorization: 'Token ' + token, 'Content-Type': 'multipart/form-data' }})
    return request.data
}


/**
 *
 * @param {*} token
 */
export async function getNotify(token) {
	try {
		let response = await axios.get(`${URLAPI}rh/notify`, {
			headers: { Authorization: "Token " + token }
		})
		response = response.data
		return response
	} catch (error) {
		console.error(`Error: ${error} `)
        return {}
	}
}