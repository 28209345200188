import { useState, createContext} from 'react'
import ModalNewCandidato from './Candidatos/ModalNewCandidato';
import ModalVoBo from './ModalVoBo'
import ModalHistorial from './ModalHistorial';
import TableCandidatos from './Candidatos/TableCandidatos';
import { DataItem, ListCompet } from '../Common/DataDisplay';
import { Grid, Paper,  Accordion, AccordionSummary, AccordionDetails, Box, Typography } from '@mui/material/';
import ButtonsVacante from './ButtonsVacante';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import dayjs from "dayjs"
import { ListTools } from 'Components/PerfilesPuesto/DetallePuesto';
import { useVacanteContext } from './VacantesContext';

export const ContextVacante = createContext()

const DetalleVacante = ({ dataVacante,days_passed }) => {
    const [expanded, setExpanded] = useState('panelCandidatos'); // PANEL
    const [openModalCand,setOpenModalCand] = useState(false) 
    const [openModalVoBo,setOpenModalVoBo] = useState(false) 
    const [openModalHist,setOpenModalHist] = useState(false) 
    const{herramientas=[]}=dataVacante
    const handleChangePanel = (panel) => (event, isExpanded) => { setExpanded(isExpanded ? panel : false); };
    function currencyFormat(num) { return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') }
    const buttons=(
        
            <ButtonsVacante 
                statusVacante={dataVacante.status_id} 
                setOpenCandidato={setOpenModalCand} 
                setOpenModalVoBo={setOpenModalVoBo} 
                setOpenModalHist={setOpenModalHist} 
                vacante={dataVacante.id} 
                /> 
            )

 
    return (
            <Grid container component={Paper} elevation={12} id={"pdf"}>
                <Grid item  xs={12} >
                <VacanteDetalle vacante={dataVacante} buttons={buttons}/>
                </Grid>
                 <ModalNewCandidato open={openModalCand} setOpen={setOpenModalCand} vacanteID={dataVacante.id}/>
               <ModalVoBo open={openModalVoBo} setOpen={setOpenModalVoBo} autorizaciones={dataVacante.vacante_autorizacion} idVacante={dataVacante.id}/>
                <ModalHistorial open={openModalHist} setOpen={setOpenModalHist} historial={dataVacante.vacante_historial}/>
                <Grid item xs={12} sx={{p:3,pt:0}}>
                    <Accordion expanded={expanded === 'panelSueldo'} onChange={handleChangePanel('panelSueldo')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header" >
                        <Box component="b" sx={{fontSize:20,color:"#1d3454" }}> Sueldo </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                <DataItem label={'Sueldo mensual'} value={currencyFormat(parseFloat(dataVacante.sueldo_mensual))} />
                                <DataItem label={'Bono trimestral'} value={dataVacante.bono_trimestral == 0 ? 'N/A' : currencyFormat(parseFloat(dataVacante.bono_trimestral)) } />
                                <DataItem label={'Garantia'} value={dataVacante.garantia == 0 ? 'N/A' : currencyFormat(parseFloat(dataVacante.garantia))} />
                                <DataItem label={'Duración'} value={dataVacante.duracion} />
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12} sx={{p:3,pt:0}}>
                    <Accordion expanded={expanded === 'panelResponsabilidades'} onChange={handleChangePanel('panelResponsabilidades')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header" >
                        <Box component="b" sx={{fontSize:20,color:"#1d3454" }}> Caracteristicas de la vacante </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                <ListCompet listItem={dataVacante.vacante_caracteristica.filter(carac => carac.tipo == 'Responsabilidades')} IconList={ThumbUpIcon} listName={"Responsabilidades"}/> 
                                <ListCompet listItem={dataVacante.vacante_caracteristica.filter(carac => carac.tipo == 'Educación')} IconList={ThumbUpIcon} listName={"Educación"}/> 
                                <ListCompet listItem={dataVacante.vacante_caracteristica.filter(carac => carac.tipo == 'Experiencia')} IconList={ThumbUpIcon} listName={"Experiencia"}/> 
                                <ListCompet listItem={dataVacante.vacante_caracteristica.filter(carac => carac.tipo == 'Adicionales')} IconList={ThumbUpIcon} listName={"Adicionales"}/> 
                                <ListTools listItem={herramientas} listName={"Herramientas de Trabajo"}/>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            { dataVacante.status_id < 3  ?  <></>:
                <Grid item xs={12} sx={{p:3,pt:0}}>
                    <Accordion expanded={expanded === 'panelCandidatos'} onChange={handleChangePanel('panelCandidatos')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header" >
                        <Box component="b" sx={{fontSize:20,color:"#1d3454" }}> Candidatos ({dataVacante.vacante_candidato.length})</Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid item xs={12} sx={{p:3,pt:0}}> 
                            <ContextVacante.Provider value={dataVacante}>
                                <TableCandidatos candidatos={dataVacante.vacante_candidato}/> 
                            </ContextVacante.Provider>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            }
            </Grid>     
    )
}

export default DetalleVacante

const VacanteDetalle=({vacante,buttons})=>{
    const{vacanteSelect}=useVacanteContext()
    const {fecha_solicitud,fecha_rh}=vacante
    const today= new Date()
    const dateRequest= dayjs(fecha_solicitud, "D-M-YYYY")
    const dateRH= dayjs(fecha_rh, "D-M-YYYY")
    const daysTrascurridos=parseInt((today-dateRequest)/(24 * 60 * 60 * 1000))
    let daysTrascurridosRh= parseInt((today-dateRH)/(24 * 60 * 60 * 1000))

    const fields=[
        {
            label:"Vacante",
            value:'#'+(vacante.id),
            size:1
            
        },
        {
            label:"Perfil",
            value:`${vacante.perfil} (${vacante.area})`,
            size:4
        },
        {
            label:"Status",
            value:vacante.status,
            size:2
        },
        {
            label:"Dias sin Movimientos",
            value:vacanteSelect.last_modification,
            size:2,
            // sizeLabel:14
        },
        {
            label:"Solicitante",
            value:vacante.jefe_nombre+' '+vacante.jefe_apellido,
            size:3
        },
        {
            label:"Sucursal",
            value:vacante.sucursal,
            size:3
        },
        {
            label:"Tipo",
            value:vacante.tipo_vacante,
            size:2
        },
        {
            label:vacante.tipo_vacante === "Reemplazo"?'¿Por Quien?':'Remplazo de',
            value:vacante.por_quien_nombre+' '+vacante.por_quien_apellido,
            size:3,
            hidden:vacante.tipo_vacante !== "Reemplazo",
            sizeLabel:14
        },
        {
            label:"Fecha Solicitud",
            value:vacante.fecha_solicitud||'N/A',
            size:2,
            sizeLabel:14
        },
        {
            label:"Dias en Proceso",
            value:daysTrascurridos,
            size:3,
            sizeLabel:14
        },
        // {
        //     label:"Fecha autorización",
        //     value:vacante.fecha_autorizacion||'N/A',
        //     size:2,
        //     sizeLabel:14
        // },
        {
            label:"Fecha Recepción RH",
            value:fecha_rh||'N/A',
            size:3,
            sizeLabel:14
        },
        {
            label:"Dias RH Recepción",
            value:daysTrascurridosRh,
            size:3,
            // hidden:!daysTrascurridosRh,
            sizeLabel:14
        },
]


const items=fields.map((item)=>{
    if(!item.hidden)
        return <DataItem2 key={item.label} {...item} />
    else
    return <Grid item key={item.label} md={item.size}>{null}</Grid>
})
items.splice(4,0,
    <Grid item key="ButtonsVacante" md={3}>
        {buttons}
    </Grid>
)

return <Box padding={2}>
    <Grid container spacing={2} >
        {items}
    </Grid>
</Box>
}


const DataItem2 = ({label, value,size=3, sizeLabel=14, fontColor= "#555", fontBold="" }) => {
    return <Grid item md={size} sx={{ p: 1 }} xs={12} sm={12}> 
                <Typography sx={{fontSize: sizeLabel, fontWeight:'bold',color: "#555"}} >  {label}</Typography>
                <Typography sx={{fontSize:sizeLabel, fontWeight: fontBold, color: fontColor}} > {value}</Typography>
            </Grid>
}