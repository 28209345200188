import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker"
import { DemoContainer } from "@mui/x-date-pickers/internals/demo"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
// import dayjs from "dayjs"
/**
 * Componente InputDateTime
 *
 * Un campo de entrada para seleccionar fecha y hora, compatible con el formato de 12 horas (AM/PM)
 * y configurado para el idioma español (México). Utiliza `DateTimePicker` de Material-UI y `Dayjs`
 * como adaptador de fechas.
 *
 * @param {Object} props - Props del componente.
 * @param {string} props.label - Etiqueta del campo de selección de fecha y hora.
 * @param {import("dayjs").Dayjs} props.value - Valor seleccionado de fecha y hora. Es un objeto Dayjs.
 * @param {function} props.onChange - Función que se ejecuta cuando se cambia el valor seleccionado.
 * @param {Array<string>} [props.views=["day", "month", "year", "hours", "minutes"]] - Vistas disponibles en el selector de fecha y hora.
 * @param {string} [props.format="DD/MMMM/YYYY hh:mm A"] - Formato de la fecha y hora.
 *
 * @example
 * <InputDateTime
 *   label="Selecciona fecha y hora"
 *   value={fechaActual}
 *   onChange={handleFechaChange}
 * />
 */
export default function InputDateTime({
	label,
	value,
	onChange,
	views = ["day", "month", "year", "hours", "minutes"],
	format = "DD/MMMM/YYYY hh:mm A",
}) {
	return (
		<LocalizationProvider
			dateAdapter={AdapterDayjs}
			adapterLocale="es-mx"
		>
			<DemoContainer components={["DateTimePicker"]}>
				<DateTimePicker
					{...{
						label,
						views,
						format,
						onChange,
						value,
					}}
					ampm
					slotProps={{
						textField: {
							helperText: format,
						},
					}}
				/>
			</DemoContainer>
		</LocalizationProvider>
	)
}
